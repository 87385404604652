.anchorBtn {
  position: absolute;
  padding: 5px;
  width: 20px;
  height: 20px;
  background: #555;
  font-size: 9px;
  font-weight: bold;
  border-style: solid;
  border-width: 0px;

  &.selected {
    border-width: 1px;
  }
}

.anchorBtn:hover {
  background: #444;
}
