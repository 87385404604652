
.container {
  position: absolute;
  z-index: 21;
  top: 70px;
  width: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #292929;
  transition: left 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.magnifier {
  position: absolute;
  border-radius: 2px;
  z-index: 50000000;
  width: 60px;
  height: 40px;
  left: 240px;
  border: 1px solid rgba(255,255,255,0.1);
  margin-top: -20px;
  pointer-events: none;
}