.mirrorOverlayX {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 3;
  border-left: 1px solid rgb(40, 255, 144);
  transition: opacity 0.2s ease-in;
}

.mirrorOverlayY {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 3;
  border-top: 1px solid rgb(255, 40, 40);
  transition: opacity 0.2s ease-in;
}

.mirrorOptions {
  position: absolute;
  width: 40px;
  height: 70px;
  background: transparent;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;

  button {
    font-size: var(--small-size);
    font-weight: bold;
    background: #131313;
    width: 30px;
    height: 30px;
    border-style: solid;
    border-width: 0px;

    &.selected {
      border-width: 1px;
    }
  }

  button:nth-child(1) {
    color: rgb(40, 255, 144);
    border-color: rgb(40, 255, 144);

    &:hover {
      color: white;
    }
  }

  button:nth-child(2) {
    color: rgb(255, 40, 40);
    border-color: rgb(255, 40, 40);

    &:hover {
      color: white;
    }
  }
}
