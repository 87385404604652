.container {
  display: flex;
  color: white;
  width: 100%;
  // background-color: red;
  padding: 25px;
  margin: 0px;
  flex-direction: column;
  justify-content: space-around;
}

.section {
  // background-color: black;
}
