@import "./layout";

.container {
  display: flex;
  width: 330px;
  padding: 10px;
  justify-content: center;
}
.container2 {
  display: flex;
  position: absolute;
  z-index: 22;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: $topBarHeight;
}
.scrollWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #212121;
}
.resetWrapper {
  transition: margin-top 0.3s ease-in-out;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
}
.option {
  min-width: 80px;
  display: flex;
  // $canvasDrawSize * 0.15
}
.optionSelector {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 10px;
  width: 40px;
  height: 80px;
  border-color: $borderColor;
  border-right-color: #242424;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.title {
  display: flex;
  width: 100%;
  height: $sectionTitleHeight;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.titleText {
  font-size: var(--small-size);
  text-align: center;
  justify-content: center;
}
.confirmBtns {
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
