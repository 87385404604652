// import layout from ../../config/layout;

.container {
  display: flex;
  position: absolute;
}

.animation {
  display: flex;
  position: absolute;
  background-color: transparent;
  bottom: 0;
  width: 150px;
  height: 150px;
  border-width: 1px;
}
.zoomWindow {
  display: flex;
  position: absolute;
  z-index: -1;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    border-color 0.2s linear;
}
