@import "./layout";

.container {
  display: flex;
  margin: 0px;
  background-color: #181818;
  position: absolute;
  top: 140px;
  left: 100px;
  z-index: 300000;
  justify-content: center;
  border: 1px solid #292929;
  border-bottom-width: 10px;
  transition: left 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95),
}
.direction {
  display: flex;
  flex-direction: column;
}
.animationControls {
  position: absolute;
  background-color: rgba(37, 37, 37, 0.8);
  width: $canvasAnimationSize;
  height: 100%;
  bottom: 0;
  border-width: 1px;
  border-right-width: 0px;
  border-color: $borderColor;
  left: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2001;
}
.animationImage {
  width: $canvasAnimationSize;
  height: $canvasAnimationSize;
}

// @media screen and (max-width: 500px) {
//   .container {
//     position: absolute;
//     top: 80px;
//     left: 10px;
//   }
// }
