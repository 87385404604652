.c {
  display: flex;
  position: absolute;
  padding: 5px;
  z-index: 3000000;
  flex-direction: column;
}
.container {
  position: absolute;
  background-color: #212121;
  padding: 10px;
  z-index: 22;
  display: flex;
  flex-direction: column;
}
.header {
  padding: 10px;
  padding-top: 10px;
  display: flex;
  height: 50%;
  flex-direction: column;
}
.headerTitle {
  color: white;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
}
.header-text {
  color: #888888;
  text-align: center;
  padding: 20px;
  padding-top: 5px;
}
.inputText {
  height: 40px;
  margin-top: 10px;
  color: #d8d8d8;
  background: rgb(59, 59, 59);
  padding: 10px;
  text-align: center;
  border-width: 0px;
}
.buttonWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
}
.btn {
  display: flex;
  align-self: center;
  min-width: 40%;
  background-color: #181818;
  align-items: center;
  padding: 20px;
  justify-content: center;
}
.btnText {
  color: #cccccc;
  text-align: center;
}
.logo {
  width: 100px;
  height: auto;
  margin-top: 10px;
  align-self: center;
}
