
  @import './layout';

  .container {
    display: flex;
    position: absolute;
    z-index: -1;
    overflow: hidden
   }
  .animContainer {
    background-size: cover;
    height: 150px;
   }
  .animation {
    display: flex;
    position: absolute;
    background-color: transparent;
    bottom: 0;
    // width: layout.dimensions.height / 2 / 2;
    // height: layout.dimensions.height / 2 / 2;
    border-width: 1;
   }
  .zoomWindow {
    display: flex;
    position: absolute;
    z-index: 2
  }
