button {
  position: relative;
  > span {
    display: flex;
    pointer-events: none;
    opacity: 0;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    background: rgba(0, 0, 0, 1);
    position: absolute;
    color: rgb(255, 255, 255);
    z-index: 10000;
    font-size: var(--small-size);
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(31, 31, 31);
    transition: opacity 0.1s ease-in-out;
  }
}

.tooltipView {
  > span {
    display: flex;
    pointer-events: none;
    opacity: 0;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    background: rgba(0, 0, 0, 1);
    position: absolute;
    color: rgb(255, 255, 255);
    z-index: 10000;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgb(31, 31, 31);
    transition: opacity 0.1s ease-in-out;
  }
}

@media (hover: hover) {
  button:hover {
    > span {
      opacity: 1;
    }
  }

  .tooltipView:hover {
    > span {
      opacity: 1;
    }
  }
}
