@import "./layout";

.c {
  display: flex;
  position: absolute;
  padding: 5px;
  z-index: 3000000;
  width: 400px;
  height: 500px;
  flex-direction: column;
  transition: top 1s ease-in-out;
}
.container {
  position: absolute;
  background-color: #212121;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 22;
  display: flex;
  width: 400px;
  height: 500px;
  flex-direction: column;
}
.bottomWrapper {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.avatar {
  border-width: 10px;
  overflow: hidden;
  border-radius: 999px;
  border-color: $borderColor;
}
.buttonContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #181818;
  align-items: center;
  justify-content: center;
  align-self: center;
  transition: all 0.4s ease-in;
}
.buttonContainerAnimated {
  border-radius: 999px;
  width: 150px;
  height: 150px;
}
.header {
  padding: 10px;
  padding-top: 10px;
  display: flex;
  height: 50%;
  flex-direction: column;
}
.headerTitle {
  color: white;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
}
.headerText {
  color: #888888;
  text-align: center;
  padding: 20px;
  padding-top: 5px;
}
.signInWrapper {
  position: absolute;
}
.btn {
  display: flex;
  background-color: #252525;
  width: 100px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.btnText {
  color: #cccccc;
  text-align: center;
}
.logo {
  width: 100px;
  height: auto;
  margin-top: 10px;
  align-self: center;
}
