@import './layout';

  .container {
    position: absolute;
    z-index: 30000000;
    background-color: transparent
  }
  .dialogBox {
    position: absolute;
    z-index: 30000001;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  .boxHeader {
    display: flex;
    height: $sectionTitleHeight;
    width: 100%;
    align-items: center;
    justify-content: center
  }
  .boxContent {
    display: flex;
    background-color: transparent;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }
  .boxBtn {
    width: 45%;
    border-width: 1px;
    align-items: center;
    padding: 10px;
    justify-content: center
  }
