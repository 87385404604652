@import "./layout";

.container {
  display: flex;
  position: absolute;
  z-index: 22;
  flex-direction: column;
  width: $framesViewWidth;
  height: 100%;
  left: 0;
  top: 30px;
  background: #0e0e0e;
  transition: margin-left 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.overlayClose {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.layersTitle {
  display: flex;
  margin-top: 0px;
  width: $framesViewWidth;
  position: absolute;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: $sectionTitleHeight;
}

.layersTitleText {
  text-align: center;
  justify-content: center;
}

.layerInterface {
  display: flex;
  margin-top: $topBarHeight + 10px;
  position: absolute;
  z-index: 21;
  width: $framesViewWidth;
  flex-direction: row;
  flex-wrap: wrap;
  height: 120px;
}

.currentLayer {
  height: 160px - $topBarHeight;
  width: $framesViewWidth * 0;
  background-color: #101010;
  align-items: center;
  justify-content: center;
}

.currentLayerText {
  padding: 10px;
  color: #666666;
  font-weight: bold;
}

.layerScroll {
  width: $framesViewWidth * 0.75;
  height: 130px;
  background-color: #202020;
  padding-left: 0;
}

.scrollWrapper {
  height: 130px;
  width: $framesViewWidth * 0.25;
  background-color: #121212;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.addNewLayer {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(39, 39, 39, 0.9);
  border-width: 1px;
  border-color: #333333;
}

.asset {
  width: 35px;
  height: 35px;
}

.frameAsset {
  width: 40px;
  height: 40px;
}

.framesTitle {
  display: flex;
  margin-top: 170px;
  width: $framesViewWidth;
  position: absolute;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.framesTitleText {
  text-align: center;
  justify-content: center;
}

.frameScroll {
  display: flex;
  width: $framesViewWidth;
  top: 210px;
  position: absolute;
  z-index: 21;
  background-color: #252525;
  overflow-x: visible;
  overflow-y: scroll;
  transition: top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.frameTools {
  width: 250px;
  height: 100px;
  position: absolute;
  display: flex;
  top: 210px;
  z-index: 20;
  align-items: center;
  justify-content: center;
}

.addNewFrame {
  flex: 1;
  width: $framesViewWidth;
  height: 90px;
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 21;
  background-color: #303030;
  align-items: center;
  justify-content: center;
}

.addFrameBtn {
  display: flex;
  align-items: center;
  height: 40%;
  justify-content: center;
}

.addFrameText {
  display: flex;
  align-items: flex-start;
  height: 40%;
  justify-content: center;
}

.addWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.indicator {
  flex: 1;
  width: calc(#{$framesViewWidth} / 3);
  height: $addFrameHeight;
  position: absolute;
  top: calc(100% - #{$addFrameHeight});
  z-index: 21;
  align-items: center;
  justify-content: center;
}

.expandBtn {
  position: absolute;
  display: flex;
  right: 0;
  width: 40px;
  top: 0px;
  height: 40px;
  background-color: #181818;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
