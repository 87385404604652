.container {
  display: flex;
  color: white;
  // background-color: red;
  padding: 10px;
  margin: 0px 10px;
  flex-direction: column;
  justify-content: space-around;
}

.section {
  display: flex;
  flex: 1;
  background-color: b lack;
}

.section:nth-child(1) {
  margin-bottom: 10px;
}
