@import "./layout";

.fpsBox {
  width: 50%;
  height: 100%;
  padding: 3px;
  background-color: #212121;
  border-width: 1px;
  border-color: $borderColor;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fpsBoxText {
  color: #fff;
  font-size: var(--small-size);
  font-weight: bold;
}
.frameIndicator {
  width: 50%;
  height: 100%;
  padding: 3px;
  background-color: #121212;
  border-width: 1px;
  border-color: $borderColor;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frameIndicatorText {
  color: #666666;
  font-size: var(--small-size);
  font-weight: bold;
  flex-direction: row;
  display: flex;
}
.frameIndicatorTextCurrent {
  color: #ccc;
  font-size: var(--small-size);
  font-weight: bold;
  padding-right: 5px;
}
