@import "./layout";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0px;
  align-items: flex-start;
}
.frameRow {
  border-color: transparent;
  border-width: 4px;
  width: $framesViewWidth;
  height: 100px;
  padding: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
}
.frameSnapshotContainer {
  // width: 25%;
}
.frameSnapshot {
  display: flex;
  flex: 1;
  border-width: 1px;
}
.frameSnapshotImageContainer {
  display: flex;
  overflow: hidden;
  width: 100px;
  height: 100px;
  // background: rgb(27, 27, 27);
  background-image: url("/assets/tra2.png");
  // background-size: cover;
  outline: 1px solid rgb(66, 66, 66);
}
.layerThumb {
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.frame64Image {
  width: 100px;
  height: 100px;
}
.frameIndexBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
}
.frameSnapshotIndexText {
  font-size: var(--small-size);
  font-weight: bold;
}
.framesBtnContainer {
  display: flex;
  flex: 1;
  // padding-left: 15%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-color: #333333;
}
.btnImage {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
}
