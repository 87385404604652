.container {
  position: absolute;
  z-index: 30000000;
}
.overlay {
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30000000;
}
.loadContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loadText {
  color: white;
  font-weight: bold;
  background-color: #111111;
  padding: 20px 0px;
  border-width: 1px;
  border-color: #3d3d3d;
}
.progressBar {
  position: absolute;
  background-color: white;
  height: 5px;
  left: 0;
  // transition: all 0.01s ease-in-out;
}
.bar {
  height: 5px;
  border-width: 1px;
  border-top-width: 0px;
  border-color: #3d3d3d;
  background-color: gray;
}
