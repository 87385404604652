@import "./layout";

.container {
  background: rgba(0, 0, 0, 0);
  border: 1px double red;
  margin: 0px;
  padding: 0px;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: $canvasTopMargin + 10;
  z-index: -50;
  align-items: center;
  justify-content: center;
}
