.container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #151319;
  // background: #0e0e0e;
}

.expandOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
}
