@import "./layout";

.btn {
  flex: 1;
  min-width: 25%;
  height: 50px;
  background-color: #181818;
  align-items: center;
  justify-content: center;
}
.btnText {
  color: gray;
}
.icon {
  width: $menuViewWidth * 0.12;
  height: $menuViewWidth * 0.12;
}

.lockbar {
  display: flex;
  flex: 1;
  max-height: 50px;
  margin: 10px 20px;
  border-width: 1px;
  padding: 10px;
  border: 1px solid #292929;
  justify-content: center;
  align-content: center;
  align-items: center;
}
