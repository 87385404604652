@import "./layout";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
.layer {
  width: 100%;
  height: 30px;
  margin-bottom: 2px;
  display: flex;
  background: #252525;
}
.layerSelector {
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  border-color: $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteLayer {
  width: 30px;
  height: 30px;
  border-color: $borderColor;
  background-color: rgb(28, 28, 28);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.opacityLayer {
  width: 40px;
  height: 30px;
  border-color: $borderColor;
  border-width: 1px;
  border-style: solid;
  background-color: rgb(28, 28, 28);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
  }
}
.btnImage {
  z-index: 2;
  width: 30px;
}
.titleText {
  text-align: center;
  justify-content: center;
  background: #252525;
  padding: 10px;
}
.opacityOverlay {
  position: absolute;
  left: 0;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(
    135deg,
    #2e2e2e 25%,
    #252525 25%,
    #252525 50%,
    #2e2e2e 50%,
    #2e2e2e 75%,
    #252525 75%,
    #252525 100%
  );
  background-size: 11.31px 11.31px;
}

.opacityOverlaySelected {
  position: absolute;
  left: 0;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(
    135deg,
    #181818 25%,
    #222222 25%,
    #222222 50%,
    #181818 50%,
    #181818 75%,
    #222222 75%,
    #222222 100%
  );
  background-size: 11.31px 11.31px;
}
