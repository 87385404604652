.container {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 3300000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s linear;
  flex-direction: column;

  > div {
    font-size: 15px;
    word-wrap: break-word;
    display: flex;
    padding: 40px;
    text-align: center;
    color: rgb(176, 176, 176);
  }

  > div:first-child {
    font-size: 20px;
    padding: 0 50px;
    color: white;
  }
}
