@import './layout';

.container {
  position: absolute;
  opacity: 0;
  top: $canvasTopMargin + 10;
  z-index: -2;
  align-items: center;
  justify-content: center;
}
