@import "./layout";

.container {
  position: absolute;
  width: $canvasDrawSize;
  height: $canvasDrawSize;
  top: $canvasTopMargin;
  z-index: 10;
  border-width: 1px;
  background-color: transparent;
  border-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: crosshair;
}
