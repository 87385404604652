.container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  z-index: 999999;
  top: 30px;
  left: 140px;
  height: 300px;
  padding: 10px;
  background: rgba(255, 0, 0, 0.308);
  flex-direction: column;
}

.btn {
  background: rgb(168, 4, 4);
  color: white;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}
