@import "./layout";

.container {
  display: flex;
  position: absolute;
  z-index: 22;
  flex-direction: column;
  width: $menuViewWidth;
  height: 100%;
  margin-top: $topBarHeight;
  transition: margin-left 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.scrollWrapper {
  position: absolute;
  width: $menuViewWidth;
  height: 100%;
  background-color: #212121;
  margin-top: 70px;
  overflow: hidden;
}
.settingsSelector {
  background-color: #181818;
  display: flex;
  width: $menuViewWidth;
  height: 70px;
  flex-direction: row;
}
.selector {
  width: $menuViewWidth * 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.selectorText {
  color: white;
  text-align: center;
}
.icon {
  width: $menuViewWidth * 0.5 / 4.5;
  height: $menuViewWidth * 0.5 / 4.5;
}
