@import "./layout";

.container {
  display: flex;
  align-self: stretch;
  position: absolute;
  top: 0px;
  z-index: 301;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
}
.direction {
  display: flex;
  height: 200px;
  flex-direction: row;
}
.animationName {
  position: absolute;
  display: flex;
  left: 0;
  z-index: 1000;
  background-color: rgba(33, 33, 33, 0.9);
  width: 150px;
  height: 100%;
  color: white;
  font-weight: 600;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.animationControls {
  position: absolute;
  background-color: rgba(37, 37, 37, 1.8);
  bottom: 0;
  border-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-color: rgba(0, 0, 0, 0.04);
  left: 0;
  top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5001;
}
.animationImage {
  width: 50px;
  height: 50px;
}
