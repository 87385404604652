@import "./layout";

.container {
  position: absolute;
  left: 0;
  // width: layout.dimensions.width;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quickNavBtn {
  opacity: 1;
  width: 50px;
  height: 40px;
  background-color: #202020;
  align-items: center;
  justify-content: center;
  border: 1px solid #292929;
}

.divColors {
  padding: 10px;
  display: flex;
  width: calc(100% - 20px);
  height: calc(100% - 35px);

  justify-items: center;

  div {
    flex: 1;
  }
}

.divRed {
  background-color: rgb(170, 30, 30);
}
.divGreen {
  background-color: rgb(34, 158, 41);
}
.divBlue {
  background-color: rgb(23, 84, 253);
}

.divTrans1 {
  background-color: rgb(52, 52, 52);
}
.divTrans2 {
  background-color: rgb(82, 82, 82);
}

.divGrid {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    border: 1px solid rgb(65, 65, 65);
  }
}

.gridRow {
  flex-direction: row;
}

.gridA {
  background-color: rgb(71, 71, 71);
  width: 8px;
  height: 8px;
}
.gridB {
  background-color: rgb(29, 29, 29);
  width: 8px;
  height: 8px;
}
