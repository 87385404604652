@import "./layout";

.container {
  position: absolute;
  left: 0;
  // width: layout.dimensions.width;
  z-index: 300000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quickNavBtn {
  width: 50px;
  height: 60px;
  background-color: #202020;
  align-items: center;
  justify-content: center;
  border: 1px solid #292929;
  padding-top: 5px;
}
