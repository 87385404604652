@import "./layout";

.container {
  position: absolute;
  z-index: 300000;
  top: 0;
  padding-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.directionContainer {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  flex-direction: row;
}

.frameIndicator {
  position: absolute;
  padding: 4px;
  background-color: #181818;
  border-width: 1px;
  border-color: $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  top: 6px;
  left: calc(#{$canvasHMargin} * 2 + 2);
}

.frameIndicatorText {
  color: #666666;
  font-weight: bold;
}

.frameIndicatorTextCurrent {
  color: #ccc;
}

.framesBtn {
  display: flex;
  background-color: transparent;
  align-content: center;
  justify-content: center;
}

.framesBtnImage {
  width: 50px;
  height: 50px;
  opacity: 0;
  pointer-events: none;
}

.layersBtn {
  display: flex;
  background-color: transparent;
  align-content: center;
  justify-content: center;
}

.layersBtnImage {
  width: 50px;
  height: 50px;
}

.logo {
  width: 50px;
  height: auto;
  margin-top: 10px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.fpsBox {
  position: absolute;
  padding: 3px;
  background-color: #181818;
  border-width: 1px;
  top: 24px;
  right: 65px;
  border-color: $borderColor;
  border-left-color: transparent;
  border-top-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.fpsBoxText {
  color: #666666;
  font-size: calc(100% * 0.025);
  font-weight: bold;
}

.loadBtn {
  background: #39314c;
  color: #9a8686;
  padding: 8px 8px;
  font-weight: bold;
  border-radius: 4px;

  &:hover {
    color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .container {
    top: 0px;
  }
}

@media screen and (max-height: 851px) {
  .logo {
    width: 60px;
    height: auto;
    margin-top: 0px;
    opacity: 1;
  }
}
