@import './layout';


.container {
  position: absolute;
  z-index: 300001;
  height: 100%;
}

.overlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.9);
  z-index: 20;
}
