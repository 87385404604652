@import "./layout";

.container {
  position: absolute;
  width: $canvasDrawSize;
  height: $canvasDrawSize;
  background: #37343e; // #1b1b1b;
  z-index: 1;
  border-color: #2e2e2e;
  align-items: center;
  justify-content: center;
}
