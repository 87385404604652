@import './layout';

.container {
  position: absolute;
  width: $canvasDrawSize;
  height: $canvasDrawSize;
  top: $canvasTopMargin;
  z-index:-100;
  background-color: rgba(0,0,0,0.5);
  border-color: $borderColor;
  align-items: center;
  justify-content: center;
  cursor: crosshair;
}
