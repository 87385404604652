@import "./layout";

.container {
  width: $menuViewWidth;
  height: 400px;
  padding: 0px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.profileInfo {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: #333333;
  padding-bottom: 10px;
}
.avatarContainer {
  display: flex;
  width: $menuViewWidth * 0.25;
  height: $menuViewWidth * 0.25;
  padding: 0px;
  border-radius: 50%;
  // background-color: #181818;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.avatar {
  overflow: hidden;
  border-color: $borderColor;
  border-radius: 50%;
}
.avatarImage {
  width: $menuViewWidth * 0.25;
  height: $menuViewWidth * 0.25;
}
.logo {
  margin-top: 20px;
  overflow: hidden;
}
.logoImage {
  width: 60px;
}

.usernameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
.signInPlease {
  color: #777;
  text-align: center;
}
.username {
  color: #fff;
}
.bottomSection {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  flex: 1;
  max-height: 55px;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #3a3a3a;
}
.signInOutWrapper {
  display: flex;
  flex: 1;
  align-items: center;
}
.signOutInBtn {
  padding: 15px;
  border-width: 1px;
  border-color: $borderColor;
  background-color: $buttonColor;
}
.signInOutBtnText {
  color: gray;
}
.listWrapper {
  display: flex;
  border-color: transparent;
  flex-direction: row;
  height: 100%;
  width: $menuViewWidth * 2;
  transition: margin-left 0.3s ease-in-out;
}
.list {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px;
  margin-top: 20px;
  overflow-y: scroll;
  border-width: 0px;
  border-color: transparent;
  border-top-color: #333333;
}
.section {
  margin: 20px 10px;
  border-bottom: 1px solid #383838;
}
.sectionTitle {
  text-transform: uppercase;
  text-align: center;
  color: white;
  padding-bottom: 10px;
}
.listItem {
  background-color: transparent;
  padding: 10px;
  // height: 80px;
  margin-bottom: 0px;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: #444444;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.listItemThumb {
  border-width: 1px;
  width: 40px;
  height: 40px;
  background: rgb(44, 44, 44);
  margin-right: 10px;
  overflow: hidden;
}
.thumb {
  width: 40px;
  height: 40px;
  background-size: cover;
  overflow: hidden;
}
.listItemText {
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  color: white;
  font-weight: bold;
}
.listItemTextActive {
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  color: gray;
  font-weight: normal;
}
.noAnimations {
  padding: 20px;
  padding-top: 10px;
}
.noAnimationsText {
  color: gray;
  text-align: center;
}
.loadItem {
  border-width: 1px;
  width: 70px;
  height: $sectionTitleHeight;
  align-items: center;
  justify-content: center;
}
.settingsItem {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-width: 1px;
  border-color: #383838;
  background-color: #131313;
}
.loadItemTxt {
  text-align: center;
}
.settingsIcon {
  width: 40px;
  height: 40px;
}
.detailsList {
  position: absolute;
  width: $menuViewWidth * 2;
  height: 5000px;
  top: 200px;
  z-index: -1;
  overflow: hidden;
}
.detailsInfo {
  display: flex;
  flex-direction: column;
  background-color: #212121;
  margin: 10px;

  width: 320px;
  color: #212121;
  flex: 1;
  border-width: 1px;
  border-color: $borderColor;
}
.detailsTopInfo {
  display: flex;
  flex-direction: row;
}
.detailsTitleText {
  display: flex;
  margin-left: 20px;
  color: white;
  margin-top: 40px;
  padding-left: 10px;
  font-style: italic;
  font-size: var(--small-size);
  text-align: center;
  justify-content: center;
}
.detailsText {
  margin-bottom: 0;
  color: gray;
  text-align: center;
}
.closeDetailsBtn {
  display: flex;
  padding: 15px;
  width: 60px;
  background-color: #181818;
  align-items: center;
  justify-content: center;
}
.optionBtn {
  padding: 15px;
  flex: 1;
  background-color: #181818;
  align-items: center;
  justify-content: center;
}
.shareBtn {
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 20px;
  padding: 15px;
  background-color: #181818;
  align-items: center;
  justify-content: center;
}
.deleteBtn {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 20px;
  padding: 15px;
  background-color: $cancelBtnColor;
  align-items: center;
  justify-content: center;
}
.buttons {
  flex-direction: row;
  justify-content: space-between;
}
.optionSelector {
  min-width: $menuViewWidth * 0.2;
  border-width: 1px;
  margin-bottom: 10px;
  border-color: $borderColor;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.confirmBtns {
  border-width: 1px;
  border-color: $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif {
  display: flex;
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 10px;
  /* padding: 15px; */
  background-color: #181818;
  align-items: center;
  justify-content: center;
}
.downloadGifBtn {
  display: flex;
  color: white;

  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  justify-content: center;
  justify-items: center;

  button {
    border-radius: 4px;
  }
}
.downloadGifBtn:nth-last-child(1) {
  margin-bottom: 0px;
}
.downloadGifText {
  display: flex;
  padding: 10px 20px;
  background: #181818;
  color: white;
  height: auto;
  border: 1px solid #313e3e;
}
