@import './layout';

.container {
  position: absolute;
  z-index: 300003;
  min-width: $menuViewWidth;
  height: 100%;
  right: -$menuViewWidth;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  z-index: 300002
}

