$topBarHeight: 30px;
$canvasHMargin: 20px;
$menuViewWidth: 350px;
$framesViewWidth: 250px;
$totalHeight: calc(100% * 0.8);
$topSectionHeight: calc(#{$totalHeight} * 0.8);
$canvasDrawSize: calc(100% / 3);
$sectionTitleHeight: 40px;
$sectionTitleFontSize: 10px;
$frameScrollY: 240px;
$canvasAnimationSize: calc(100% / 2 / 2);
$canvasPixelBlur: 0;

$topSectionMarginTop: $topBarHeight;
$canvasTopMargin: 400px;

$canvasFrameSize: calc(100% - (#{$topBarHeight} / 7));
$sectionTitleBackground: #191919;
$sectionTitleColor: #666666;
$buttonColor: #191919;
$cancelBtnColor: #871414;
$gridBgLight: #232323;
$gridBgDark: #191919;
$borderColor: rgba(0, 0, 0, 0.04);
$activeBorderColor: #494949;
$addFrameHeight: 120px;
