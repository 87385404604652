@import "./layout";

.container {
  display: flex;
  align-self: flex-end;
  padding: 0px;
  position: relative;
  margin-right: 0px;
  width: 60px;
}
.edges {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  border-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: $borderColor;
}
.positionRow {
  display: flex;
  position: absolute;
  flex-direction: column;
}
.btns {
  display: flex;
  width: 55px;
  height: 55px;
}
.drawContainer {
  flex: 1;
  background-color: transparent;
}
.image {
  width: 100%;
  height: 100%;
}
.imageBackground {
  display: flex;
  width: 100%;
  height: 1px;
}
