@import "./layout";

.container {
  position: absolute;
  left: 90px;
  z-index: 10;
  width: 70px;
  height: 250px;
  border-width: 1px;
  background-color: #121212;
  border: 1px solid #292929;
  transition: left 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 0.1s linear;
}
.barMask {
  width: 70px;
  height: 100%;

  left: 0;
  display: flex;
}
.bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 70px;
  flex-direction: column;
  transition: opacity 0.5s linear;
}

.item {
  width: 50px;
  height: auto;
  align-items: "center";
}

// @media screen and (max-width: 500px) {
//   .container {
//     top: 80px;
//   }
// }
