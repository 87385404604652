@import "./layout";

.animationView {
  position: absolute;
  background-color: rgb(37, 37, 37);
  z-index: 30000;
  display: flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.zoomView {
  display: flex;
  position: absolute;
  z-index: 30000;
  display: flex;
  justify-content: center;
  top: 120px;
  width: 150px;
  height: 50px;
  background: #1a1a1a;
}
