:root {
  --small-size: 10px;
  --big-size: 10px;
}

@font-face {
  font-family: "Fira Code";
  src: local("font6"), url(../../font6.ttf) format("truetype");
}

html,
body {
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  width: 100%;
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #111013;
  color: #f2f2f2;
  padding-top: 50px;
  margin: 0 auto;
  font-family: "Fira Code";
  font-size: var(--small-size);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}
