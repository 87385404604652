@import "./layout";

.container {
  display: flex;
  height: auto;
  align-self: flex-end;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: $borderColor;
  border-bottom-color: $borderColor;
  border-top-color: $borderColor;
}
.toolBase {
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: $borderColor;
  background-color: transparent;
}
.tools {
  display: flex;
  height: 100%;
  width: 50%;
}
.tool {
  display: flex;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 6px;
}
.tool:first-child {
  padding-top: 10px;
}
.toolTouch {
  flex: 1;
  width: 40px;
  height: 80px;
  background-color: transparent;
}
.toolImage {
  // display: flex;
  // align-self: center;
  width: 40px;
  height: 40px;
  // margin: 0px;
}
.toolBackground {
  width: 100%;
  height: 1px;
}

.gradientbox {
  position: absolute;
  width: 70px;
  height: 30px;
  pointer-events: none;
}

.gradientTop {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#121212+0,121212+100&1+0,0+100 */

  background: -webkit-linear-gradient(
    top,
    rgba(18, 18, 18, 1) 0%,
    rgba(18, 18, 18, 0.5) 50%,
    rgba(18, 18, 18, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
}

.gradientBottom {
  bottom: 0px;

  background: -webkit-linear-gradient(
    top,
    rgba(18, 18, 18, 0) 0%,
    rgba(18, 18, 18, 0.5) 50%,
    rgba(18, 18, 18, 1) 100%
  );
}
// @media screen and (max-width: 500px) {
//   .container {
//     display: flex;
//     height: 100px;
//     align-self: flex-end;
//     flex-direction: column;
//     background-color: rgba(0,0,0,0.1);
//     border-color: $borderColor;
//     border-bottom-color: $borderColor;
//     border-top-color: $borderColor;

//   }
// }
